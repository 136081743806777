import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/index.scss";
import Antd from "ant-design-vue";
import VueLazyload from 'vue-lazyload'
import "ant-design-vue/dist/antd.css";
import VueI18n from 'vue-i18n'
import ajax from './utils'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('languageSet') || 'zh',   //从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    'zh': require('./components/language/zh'),
    'en': require('./components/language/en')
  }
})
Vue.use(VueLazyload, {
  loading: require('./assets/init.png')
})

Vue.prototype.$ajax = ajax;
Vue.use(Antd);
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import '@/style/element/theme/index.css' // ElementUI自定义主题
Vue.use(ElementUI);

import '@/style/common.scss'

router.afterEach(() => {
  setTimeout(() => {
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?7868d18816e58165050172a25ddf1782";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }, 0);
});

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0
  next()
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
