<template>
  <!-- 页脚 -->
  <div>
    <!-- 其他平台 -->
    <div class="platform-wrap" :class="$i18n.locale == 'en' ? 'en' : ''">
      <div class="item">
        <div class="item-1">
          <!-- 抖音 -->
          <el-popover placement="right" width="100" trigger="hover">
            <img
              src="@/assets/version2/dyQRCode.png"
              alt=""
              width="125px"
              height="125px"
            />
            <div slot="reference" class="item-1-wrap">
              <div class="item-img img-1"></div>
              <div>抖音</div>
            </div>
          </el-popover>
          <!-- 哔哩哔哩 -->
          <div
            class="item-1-wrap"
            @click="
              goPage(
                'https://space.bilibili.com/495105484?from=search&seid=16976622687779562880&spm_id_from=333.337.0.0'
              )
            "
          >
            <div class="item-img img-2"></div>
            <div>哔哩哔哩</div>
          </div>
          <!-- YouTube -->
          <div
            class="item-1-wrap"
            @click="goPage('https://www.youtube.com/c/suzhouco')"
          >
            <div class="item-img img-3"></div>
            <div>YouTube</div>
          </div>
          <!-- 微博 -->
          <div
            class="item-1-wrap"
            @click="goPage('https://weibo.com/suzhouco?is_all=1')"
          >
            <div class="item-img img-4"></div>
            <div>微博</div>
          </div>
          <!-- 微信 -->
          <el-popover placement="right" width="100" trigger="hover">
            <img
              src="@/assets/version2/wxQRCode.jpg"
              alt=""
              width="125px"
              height="125px"
            />
            <div slot="reference" class="item-1-wrap">
              <div class="item-img img-5"></div>
              <div>微信</div>
            </div>
          </el-popover>
          <!-- facebook -->
          <div
            class="item-1-wrap"
            @click="goPage('https://www.facebook.com/suzhouco')"
          >
            <div class="item-img img-7"></div>
            <div>facebook</div>
          </div>
          <!-- TikTok -->
          <div
            class="item-1-wrap"
            @click="
              goPage(
                'https://www.tiktok.com/@suzhouchineseorchestra/video/7088207782054300970?is_from_webapp=1&sender_device=pc&web_id=7079018117335434753'
              )
            "
          >
            <div class="item-img img-8"></div>
            <div>TikTok</div>
          </div>
          <!-- Instagram -->
          <div
            class="item-1-wrap"
            @click="goPage('https://www.instagram.com/suzhouchineseorchestra/')"
          >
            <div class="item-img img-9"></div>
            <div>Instagram</div>
          </div>
          <!-- 竖线 -->
          <div class="item-1-wrap img-6"></div>
        </div>
        <div class="item-2">{{ $t("footer.OfficialAccount") }}</div>
      </div>
    </div>
    <div class="home-footer">
      <div class="w1200">
        <div class="flex between">
          <div>
            <img
              src="../assets/footer-logo.png"
              style="width: 556px; height: 112px"
              alt=""
              v-if="$i18n.locale == 'zh'"
            /><img
              src="../assets/footer-logoEn.png"
              alt=""
              style="width: 556px; height: 112px"
              v-else
            />
          </div>
          <!-- 赞助 -->
          <div class="sponsor-wrap">
            <div class="sponsor_text">{{ $t("footer.sponsor") }}</div>
            <img class="sponsor_img" src="../assets/version2/sponsor.png" />
          </div>
        </div>
        <div
          class="right"
          :class="$i18n.locale == 'en' ? 'mt32' : ' le-2 mt36'"
        >
          <div class="fs18">{{ $t("footer.name") }}</div>
          <div
            class="flex between fs16 fw300"
            :class="$i18n.locale == 'en' ? 'mt8' : 'mt16'"
          >
            <div
              class="flex v-center"
              :style="{ width: $i18n.locale == 'en' ? '485px' : 'auto' }"
            >
              <img class="mr12" src="../assets/version2/home-27.png" />{{
                $t("footer.site")
              }}
            </div>
            <div class="flex v-center">
              <img
                class="mr12"
                src="../assets/mail-icon.png"
              />suzhousco@foxmail.com
            </div>
            <div class="flex v-center">
              <img class="mr12" src="../assets/phone-icon.png" />0512-69589013
            </div>
          </div>
        </div>
        <div
          class="c flex"
          :style="{ marginTop: $i18n.locale == 'en' ? '41px' : '46px' }"
        >
          <div>
            {{ $t("footer.company") }}
            <a
              href="https://beian.miit.gov.cn/"
              style="color: #fff"
              target="_blank"
              >{{ $t("footer.record") }}</a
            >
          </div>
          <div class="ml-50">{{ $t("footer.other1") }}</div>
          <div class="ml-20">{{ $t("footer.other2") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goPage(url) {
      window.open(url, "_blank");
    },
  },
};
</script>


<style lang="scss" scoped>
// 页脚
.home-footer {
  width: 100%;
  padding-top: 44px;
  height: 440px;
  color: #fff;
  font-size: 16px;
  background: url("../assets/version2/home-26.png") no-repeat 100% 100%
    rgba(99, 70, 54, 1);
  letter-spacing: 1px;
  overflow: hidden;
  p {
    font-size: 16px;
    font-weight: 300;
  }
  .right {
    text-align: left;
    opacity: 0.8;
  }
  .c {
    font-size: 14px;
    font-weight: 300;
    color: #fcfcfc;
    text-align: left;
    opacity: 0.8;
  }
}

.platform-wrap {
  width: 100%;
  background: #e8eef0;
  text-align: center;
  .item {
    width: 1200px;
    margin: 0 auto;
    display: flex;
  }
  .item-1 {
    width: 1036px;
    display: flex;
    justify-content: space-between;
    .item-1-wrap {
      margin-top: 26px;
      cursor: pointer;
      font-size: 16px;
      color: #b3b3b3;
      letter-spacing: 1px;
      text-align: center;
      width: 80px;
    }
    .item-img {
      background-size: 100% 100%;
      margin-top: 4px;
      margin: 0 auto;
    }
    .img-1 {
      width: 26px;
      height: 26px;
      background-image: url("../assets/version2/home-21.png");
      cursor: pointer;
    }
    .img-2 {
      width: 26px;
      height: 26px;
      background-image: url("../assets/version2/home-22.png");
    }
    .img-3 {
      width: 36px;
      height: 26px;
      background-image: url("../assets/version2/home-23.png");
    }
    .img-4 {
      width: 30px;
      height: 24px;
      background-image: url("../assets/version2/home-24.png");
    }
    .img-5 {
      width: 33px;
      height: 26px;
      background-image: url("../assets/version2/home-25.png");
    }
    .img-7 {
      width: 26px;
      height: 26px;
      background-image: url("../assets/version2/home-29.png");
    }
    .img-8 {
      width: 26px;
      height: 26px;
      background-image: url("../assets/version2/home-30.png");
    }
    .img-9 {
      width: 26px;
      height: 26px;
      background-image: url("../assets/version2/home-31.png");
    }
    .img-6 {
      display: inline-block;
      width: 1px;
      height: 54px;
      opacity: 1;
      background: #cccccc;
      margin-top: 23px;
    }
  }
  .item-2 {
    width: 164px;
    opacity: 0.55;
    font-size: 24px;
    font-weight: 300;
    color: #4d4d4d;
    line-height: 101px;
  }
}
.en .item-2 {
  font-size: 21px;
}
.sponsor-wrap {
  max-width: 238px;
  .sponsor_text {
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 1px;
    opacity: 0.8;
  }
  .sponsor_img {
    margin-top: 25px;
    width: 120px;
    height: 82px;
  }
}
</style>
