<template>
  <div class="v2-header-wrap" :class="$i18n.locale == 'en' ? 'en' : ''">
    <div class="v2-h-logo" @click="gotoHome">
      <img
        src="@/assets/version2/logo2.png"
        class="logo-img"
        alt=""
        v-if="$i18n.locale == 'zh'"
      />
      <img src="@/assets/version2/logoEn2.png" class="logo-img" alt="" v-else />
    </div>
    <div class="v2-h-nav">
      <!-- 关于乐团 -->
      <div :class="['menu-item', active == '1' ? 'menu-item-active' : '']">
        <div class="sub-item">
          <span
            >{{ $t("v2headline1.name") }}<i class="menu-item-icon"></i
          ></span>
        </div>
        <!-- 下拉 -->
        <div class="sub-list">
          <!-- 苏文投集团 -->
          <div class="second-item">
            <a href="http://www.scidg.com.cn/#/pc/index" target="_blank"
              ><span>{{ $t("v2headline1.suwentou") }}</span></a
            >
          </div>
          <!-- 乐团简介 -->
          <router-link class="second-item" :to="{ name: 'intro' }">{{
            $t("v2headline1.intro")
          }}</router-link>
          <!-- 乐团艺术家 -->
          <router-link class="second-item" :to="{ name: 'artist' }">{{
            $t("v2headline1.artist.name")
          }}</router-link>
          <!-- 乐团领导 -->
          <router-link class="second-item" :to="{ name: 'orchestraLeader' }">{{
            $t("v2headline1.orchestraLeader")
          }}</router-link>
          <!-- 乐团顾问 -->
          <router-link class="second-item" :to="{ name: 'artistic' }">{{
            $t("v2headline1.artisticConsultant")
          }}</router-link>
          <!-- 运营团队 -->
          <router-link class="second-item" :to="{ name: 'team' }">{{
            $t("v2headline1.ExecutiveManagementTeam")
          }}</router-link>
        </div>
      </div>
      <!-- 乐团演出 -->
      <div :class="['menu-item', active == '2' ? 'menu-item-active' : '']">
        <div class="sub-item">
          <span
            >{{ $t("v2headline2.name") }}<i class="menu-item-icon"></i
          ></span>
        </div>
        <div class="sub-list">
          <!-- 音乐季 -->
          <router-link class="second-item" :to="{ name: 'season' }">{{
            $t("v2headline2.musicSeason")
          }}</router-link>
          <!-- 国际巡演 -->
          <router-link
            class="second-item"
            :to="{ name: 'othershow', query: { type: 0 } }"
            >{{ $t("v2headline2.internationalTour") }}</router-link
          >
          <!-- 委约作品 -->
          <router-link class="second-item" :to="{ name: 'production' }">{{
            $t("v2headline2.weiYaoWorks")
          }}</router-link>
        </div>
      </div>
      <!-- 乐团资讯 -->
      <div :class="['menu-item', active == '3' ? 'menu-item-active' : '']">
        <div class="sub-item">
          <span
            >{{ $t("v2headline3.name") }}<i class="menu-item-icon"></i
          ></span>
        </div>
        <div class="sub-list">
          <!-- 大事记 -->
          <router-link
            class="second-item"
            :to="{ name: 'chronicleOfEvents' }"
            >{{ $t("v2headline3.chronicleOfEvents") }}</router-link
          >
          <!-- 乐团新闻 -->
          <router-link class="second-item" :to="{ name: 'news' }">{{
            $t("v2headline3.orchestraNews")
          }}</router-link>
        </div>
      </div>
      <!-- 艺术教育 -->
      <div :class="['menu-item', active == '4' ? 'menu-item-active' : '']">
        <div class="sub-item">
          <span
            >{{ $t("v2headline4.name") }}<i class="menu-item-icon"></i
          ></span>
        </div>
        <div class="sub-list" v-if="$i18n.locale == 'zh'">
          <router-link class="second-item" :to="{ path: 'training?type=1' }">{{
            $t("v2headline4.artTraining")
          }}</router-link>
          <router-link class="second-item" :to="{ path: 'training?type=2' }">{{
            $t("v2headline4.SocialArtLevelExamination")
          }}</router-link>
          <router-link class="second-item" :to="{ path: 'training?type=3' }">{{
            $t("v2headline4.saishizhanyan")
          }}</router-link>
        </div>
      </div>
      <!-- 演出购票 -->
      <div :class="['menu-item', active == '5' ? 'menu-item-active' : '']">
        <div class="sub-item">
          <a href="https://www.sucoch.com/#/" target="_blank"
            ><span>{{ $t("v2headline5") }}</span></a
          >
        </div>
      </div>
      <!-- 联系我们 -->
      <div :class="['menu-item', active == '6' ? 'menu-item-active' : '']">
        <div class="sub-item">
          <span
            >{{ $t("v2headline6.name") }}<i class="menu-item-icon"></i
          ></span>
        </div>
        <div class="sub-list">
          <router-link class="second-item" :to="{ name: 'join' }">{{
            $t("v2headline6.joinus")
          }}</router-link>
          <router-link class="second-item" :to="{ name: 'business' }">{{
            $t("v2headline6.businessCooperation")
          }}</router-link>
          <!-- 音乐厅导览 -->
          <div class="second-item">
            <a href="https://c.sucoch.com/vr/vr/index.html" target="_blank"
              ><span>{{ $t("v2headline6.concertHallGuide") }}</span></a
            >
          </div>
          <!-- 停车引导 -->
          <div class="second-item">
            <a href="https://c.sucoch.com/vr/tc/index.html" target="_blank"
              ><span>{{ $t("v2headline6.parkingGuidance") }}</span></a
            >
          </div>
          <!-- 租借乐谱 -->
          <router-link class="second-item" :to="{ name: 'LoanSheetMusic' }" v-if="$i18n.locale == 'zh'">{{
            $t("v2headline6.LoanSheetMusic")
          }}</router-link>
        </div>
      </div>
    </div>
    <div class="v2-h-right">
      <div class="r-text-1" style="padding: 0 2%">
        <a
          href="https://www.culturedc.cn/thematic/suminle/index.html"
          style="color: #fff"
          target="_blank"
          ><span>{{ $t("v2headline7") }}</span></a
        >
      </div>
      <!-- 艺术团队联合会 -->
      <div
        class="r-text-1"
        style="padding: 0 2%; margin-left: 5%; color: #fff"
        :class="[active == '7' ? 'menu-item-active' : '']"
        v-if="$i18n.locale == 'zh'"
      >
        <router-link :to="{ path: 'artTeamUnion' }" style="color: #fff">
          {{ $t("v2headline8") }}</router-link
        >
      </div>
      <div class="r-text-2 el-icon-search"></div>
      <div class="r-text-3" @click="changeLanguage()">
        {{ $t("language.name") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "active"],
  data() {
    return {};
  },
  methods: {
    gotoHome() {
      if (this.type == "home") return false;
      this.$router.push({ name: "home" });
    },
    changeLanguage() {
      this.$i18n.locale == "zh"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "zh"); //设置中英文模式
      localStorage.setItem("languageSet", this.$i18n.locale); //将用户设置存储到localStorage以便用户下次打开时使用此设置
      location.reload(); //中英切换刷新当前页面
    },
  },
};
</script>

<style scoped lang="scss">
.v2-header-wrap {
  display: flex;
  text-align: center;
  background: #333333;
  .v2-h-logo {
    width: 24%;
    background: #634636;
    line-height: 100px;
    cursor: pointer;
    .logo-img {
      width: 340px;
      height: 68px;
    }
  }
  .v2-h-nav {
    display: flex;
    width: 47%;
    font-size: 16px;
    font-weight: 300;
    color: #fff;
    .menu-item {
      width: 17%;
      background: #333333;
      cursor: pointer;
      position: relative;
      transition: all 0.3s;
      .sub-item {
        line-height: 100px;
        height: 100px;
        word-break: normal;
        span {
          display: inline-block;
          vertical-align: middle;
          line-height: 22px;
        }
        .menu-item-icon {
          width: 0;
          height: 0;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-top: 6px solid #fff;
          display: inline-block;
          vertical-align: middle;
          margin-left: 6px;
        }
        a {
          color: #fff;
        }
      }
      .sub-list {
        display: none;
        width: 100%;
        position: absolute;
        top: 100px;
        left: 0;
        z-index: 100;
        opacity: 0.7;
        background: #634636;
        padding: 20px 0;
        .second-item {
          width: 100%;
          display: block;
          padding: 12px 0;
          color: #fff;
          position: relative;
          transition: all 0.3s;
          a {
            color: #fff;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .second-list {
            display: none;
            width: 100%;
            position: absolute;
            left: 100%;
            top: 0;
            z-index: 110;
            text-align: center;
            color: #fff;
            background: #634636;
            padding: 20px 0;
            .econd-list-a {
              display: block;
              padding: 12px 0;
              color: #fff;
              transition: all 0.3s;
              &:hover {
                background-color: #b49a71;
              }
            }
          }
          &:hover {
            background-color: #b49a71;
            .second-list {
              display: block;
            }
          }
        }
      }
      &:hover {
        background: #634636;
        .sub-list {
          display: block;
        }
      }
    }
    .menu-item-active {
      background: #634636;
    }
  }
  .v2-h-right {
    width: 29%;
    display: flex;
    background: #333333;
    line-height: 100px;
    height: 100px;
    .r-text-1 {
      font-size: 16px;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
      white-space: nowrap;
      padding: 0 5.25%;
      transition: all 0.3s;
      &:hover {
        background: #634636;
      }
    }
    .r-text-2 {
      width: 57px;
      height: 28px;
      line-height: 27px;
      border: 1px solid #a9a9a7;
      border-radius: 4px;
      margin-left: 6.55%;
      margin-top: 36px;
      color: #aaaaaa;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: #634636;
        border: 1px solid #634636;
      }
    }
    .r-text-3 {
      width: 56px;
      height: 28px;
      line-height: 26px;
      border: 1px solid #a9a9a7;
      border-radius: 4px;
      margin: 36px 1% 0 5.51%;
      font-size: 13px;
      font-family: Roboto, Roboto-Regular;
      font-weight: 400;
      color: #aaaaaa;
      cursor: pointer;
      white-space: nowrap;
      transition: all 0.3s;
      &:hover {
        background: #634636;
        border: 1px solid #634636;
      }
    }
    .menu-item-active {
      background: #634636;
    }
  }
}
.en {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important; //等宽字体
  .v2-h-right {
    .r-text-1 {
      width: 165px;
      white-space: normal;
      span {
        display: inline-block;
        line-height: 22px;
        vertical-align: middle;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .v2-header-wrap {
    .v2-h-nav {
      font-size: 14px;
    }
    .v2-h-right {
      .r-text-1 {
        font-size: 14px;
      }
    }
  }
}
</style>
