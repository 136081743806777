import axios from "axios";
import qs from "qs";

axios.defaults.timeout = 15000; //响应时间
// axios.defaults.headers.post["Content-Type"] =
// "application/x-www-form-urlencoded;charset=UTF-8"; //配置请求头
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// axios.defaults.baseURL = "http://192.168.31.96:8889/"; //配置接口地址
axios.defaults.baseURL = "https://api.suzhousco.com/"; //配置接口地址

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  (config) => {
    //在发送请求之前做某件事
    if (config.method === "post" && config.isQS) {
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    console.log("错误的传参");
    return Promise.reject(error);
  }
);

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (res) => {
    //对响应数据做些事
    if (res.status === 200) {
      return Promise.resolve(res.data);
    }
    return res;
  },
  (error) => {
    console.log("网络异常");
    return Promise.reject(error);
  }
);

//返回一个Promise(发送post请求)
export function fetchPost(url, params, configs) {
  return new Promise((resolve, reject) => {
    if (params) {
      params.lang = localStorage.getItem('languageSet') == 'en' ? '1' : '0'
    }
    let con = configs || ''
    axios
      .post(url, params, con)
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}
////返回一个Promise(发送get请求)
export function fetchGet(url, param) {
  return new Promise((resolve, reject) => {
    if (param) {
      param.lang = localStorage.getItem('languageSet') == 'en' ? '1' : '0'
    }
    axios
      .get(url, { params: param })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

function imgUploadUrl(url) {
  return 'https://suco.oss-cn-hangzhou.aliyuncs.com/' + url || ''
}

function localOrOnline () {
  // return '';
  return 'api/';
}

export default {
  post: fetchPost,
  get: fetchGet,
  imgUploadUrl,
  localOrOnline
};
