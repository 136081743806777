import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home2.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/orchestra",
    name: "intro",
    component: () => import(/* webpackChunkName: "intro" */ "../views/1-Intro2.vue")
  },
  {
    path: "/artist",
    name: "artist",
    component: () => import(/* webpackChunkName: "artist" */ "../views/2-artist2.vue") // 乐团艺术家
  },
  {
    path: "/consultant",
    name: "artistic",
    component: () => import(/* webpackChunkName: "artistic" */ "../views/4-artistic2.vue")
  },
  {
    path: "/works",
    name: "production",
    component: () => import(/* webpackChunkName: "production" */ "../views/5-production.vue")
  },
  {
    path: "/works/:id",
    name: "production-detail",
    component: () => import(/* webpackChunkName: "production" */ "../views/5-production-detail.vue")
  },
  {
    path: "/team",
    name: "team",
    component: () => import(/* webpackChunkName: "team" */ "../views/6-team2.vue")
  },
  {
    path: "/chronicleOfEvents",
    name: "chronicleOfEvents",
    component: () => import(/* webpackChunkName: "team" */ "../views/20-chronicleOfEvents.vue") // 大事记
  },
  {
    path: "/tx",
    name: "tx",
    component: () => import(/* webpackChunkName: "team" */ "../views/21-tx.vue") // 团训
  },
  {
    path: "/orchestraLeader",
    name: "orchestraLeader",
    component: () => import(/* webpackChunkName: "team" */ "../views/19-orchestraLeader.vue") // 乐团领导
  },
  {
    path: "/news",
    name: "news",
    component: () => import(/* webpackChunkName: "news" */ "../views/7-news.vue")
  },
  {
    path: "/news/:id",
    name: "news-detail",
    component: () => import(/* webpackChunkName: "news" */ "../views/7-news-detail.vue")
  },
  {
    path: "/music",
    name: "season",
    component: () => import(/* webpackChunkName: "season" */ "../views/8-season2.vue")
  },
  {
    path: "/concert",
    name: "show",
    component: () => import(/* webpackChunkName: "show" */ "../views/9-show.vue")
  },
  {
    path: "/concert/:id",
    name: "show-detail",
    component: () => import(/* webpackChunkName: "show" */ "../views/9-show-detail.vue")
  },
  {
    path: "/party",
    name: "party",
    component: () => import(/* webpackChunkName: "party" */ "../views/11-party.vue")
  },
  {
    path: "/party/:id",
    name: "party-detail",
    component: () => import(/* webpackChunkName: "party" */ "../views/11-party-detail.vue")
  },
  {
    path: "/training",
    name: "training",
    component: () => import(/* webpackChunkName: "training" */ "../views/12-training2.vue"),
  },
  {
    path: '/training/testRegistration',
    name: 'testRegistration',
    component: () => import(/* webpackChunkName: "business" */ "../views/15-testRegistration.vue")
  },
  {
    path: '/training/scoreInquiry',
    name: 'scoreInquiry',
    component: () => import(/* webpackChunkName: "business" */ "../views/16-scoreInquiry.vue")
  },
  {
    path: '/training/testSearch',
    name: 'testSearch',
    component: () => import(/* webpackChunkName: "business" */ "../views/17-testSearch.vue")
  },
  {
    path: '/training/testUserInfo',
    name: 'testUserInfo',
    component: () => import(/* webpackChunkName: "business" */ "../views/18-testUserInfo.vue")
  },
  {
    path: '/brandActivity',
    name: 'brandActivity',
    component: () => import(/* webpackChunkName: "business" */ "../views/22-brandActivity.vue") // 品牌活动
  },
  {
    path: '/publicBenefit',
    name: 'publicBenefit',
    component: () => import(/* webpackChunkName: "business" */ "../views/23-publicBenefit.vue") // 公益惠民
  },
  {
    path: "/join",
    name: "join",
    component: () => import(/* webpackChunkName: "join" */ "../views/13-join2.vue") // 加入我们
  },
  {
    path: "/contact",
    name: "business",
    component: () => import(/* webpackChunkName: "business" */ "../views/14-business.vue")
  },
  {
    path: "/usshow",
    name: "usshow",
    component: () => import(/* webpackChunkName: "business" */ "../views/24-usshow.vue") // 美国展演
  },
  {
    path: "/othershow",
    name: "othershow",
    component: () => import(/* webpackChunkName: "business" */ "../views/25-othershow.vue") // 其他展演
  },
  {
    path: "/artTeamUnion",
    name: "artTeamUnion",
    component: () => import(/* webpackChunkName: "business" */ "../views/artTeamUnion.vue") // 艺术团队联合会
  },
  {
    path: "/europe",
    name: "europe",
    component: () => import(/* webpackChunkName: "business" */ "../views/26-europe.vue") // 欧洲展演
  },
  {
    path: "/LoanSheetMusic",
    name: "LoanSheetMusic",
    component: () => import(/* webpackChunkName: "business" */ "../views/LoanSheetMusic.vue") // 租借乐谱
  },
];

const router = new VueRouter({
  routes
});

export default router;
